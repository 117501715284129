import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import Unsubscribe from '../modals/unsubscribe'

const MyAccountUnsubscribe = withSubtheme((props) => {
    const {token, location, source } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Unsubscribe onClose={() => void 0} source={source} token={token} location={location} />
            </Grid>
        </Grid>
    )
}, 'unsubscribePage')

export default React.memo(MyAccountUnsubscribe);
